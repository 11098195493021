@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

body {
    margin: 0;
    padding: 0;
}
.navbar {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 10px 20px; 
    z-index: 0;
}

.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.form-inline {
    display: flex;
    align-items: center;
    margin-right: 10px; 
    z-index: 1;
}

.form-control {
    margin-right: 5px; 
    z-index: 1;
}

.navbar-brand {
    font-size: 32px; 
    font-family: 'Arial', sans-serif;
    color: #fff; 
    margin-right: auto;
}

    .navbar-brand img {
        max-height: 100px; 
        margin-right: 40px; 
        margin-left: 40px;
    }

.navbar-nav .nav-link {
    color: #fff; 
    font-weight: bold;
    font-size: 17px;
    text-align: center;
    font-family: 'Roboto', sans-serif; 
    font-weight: 100;
    z-index: 0;
   
    margin-top: 100px;   
}


.navbar-nav .nav-item .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1); 
    border-radius: 5px; 
    transform: scale(1.1);
}


.navbar-toggler-icon {
    background-color: #fff; 
}

footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    position: fixed;
    bottom: 0;

}


@media (max-width: 1400px) {

    .navbar-nav .nav-link {
        font-size: 12px; 
        margin-bottom: 5px; 
        width: 100%; 
        text-align: center; 
      }
    
}



@media (max-width: 1250px) {
    .navbar {
        width: 100%;
    }


    .navbar-nav {
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
    }

    .navbar-nav .nav-item {
        margin-right: 10px; 
        
        margin-left: -25px;
    
     
    }


    .navbar-nav .nav-link {
      
        margin-bottom: 4px; 
        width: 100%; 
        text-align: center; 
        word-wrap: break-word;
      }


    

}
@media (max-width: 850px) {
    .navbar {
        width: 100%;
    }

    .navbar-brand img {
        max-height: 100px; 
        margin-right: 1px; 
        margin-left: 20px;
    }

    .navbar-nav {
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
     
    }
    .navbar-nav .nav-item {
        margin-right: 10px; 
        margin-left: -15px;
        font-size: 13px; 
    
     
    }

    
  


    .navbar-nav .nav-link {
        font-size: 13px; 
        margin-bottom: 5px; 
        width: 100%; 
        text-align: left; 
     
      }

    .navbar-toggler {
        margin-left: auto;
    }

 


    .custom-search {
        margin-right: auto;
        max-width: 120px;
        max-height: 60px;
    }
}



@media (max-width: 550px) {

    .navbar {
        width: 100%;
    }

    .navbar-brand img {
        max-height: 80px; 

        margin-left: 20px;
    }

    .navbar-nav {
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
    }


       

        .navbar-nav .nav-link {
            font-size: 3px; 
            margin-bottom: 4px; 
            margin-right: 10px;
            width: 100%; 
            text-align: center; 
            word-wrap: break-word;
          }

    .navbar-toggler {
        margin-left: auto;
    }

    .custom-search {
        margin-right: auto;
        max-width: 120px;
        max-height: 60px;
    }
    .form-control{
        width: 100px;
    }

    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: lightgray;
        text-align: center;
        padding: 10px 0;
    }
    
   
      
      
}






