@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Press+Start+2P&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

.main-container {
  background-image: url('../images/pozadie.jpeg');
  background-size: cover;
  background-position: center;
  height: 100vh; /* Ensure the container fills the viewport height */
}

.ruka-image {
  position: absolute;
  height: 75%;
  top: 45%; /* Adjust as needed */
  left: 36%; /* Adjust as needed */
  z-index: 0; /* Ensure the ruka image is above the background */
}
.phone {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.welcome-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  width: fit-content; /* New style to fit the content */
}

.welcome-message p {
  margin: 30px 0;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  display: block; /* Each <p> on its own line */
}

.welcome-line1 {
  color: white;
  font-size: 2rem; /* Using 'rem' for consistency */
  font-family: "Montserrat", sans-serif;
}

.welcome-line2 {
  color: white;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
}

.welcome-line3 {
  color: white;
  font-size: 2.5rem;
  font-family: "Press Start 2P", system-ui;
}

.welcome-line4 {
  color: white;
  font-size: 0.9rem;
  white-space: nowrap;
  margin-top: 1rem; /* Space after the previous line */
}

.welcome-line5 {
  color: white;
  font-size: 4rem;
  animation: moveArrow 2s infinite alternate;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-top: 1.5rem; /* Space after the previous line */
}


@keyframes moveArrow {
  0% {
    transform: translateY(0); /* Initial position */
  }
  100% {
    transform: translateY(30px); /* Move arrow down by 20px */
  }
}


.phone img {
  margin-top: 1%;
  max-width: 100%;
  height: auto;
  width: 41%;
  z-index: 1 ;
}

.centered-container {
  position: absolute;
  top: 50%;
  left: 62.8%;
  width: 43%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 1;
}

.chats {
  position: absolute;
  top: 5%;
  font-size: 1em;
  left: 2%;
  width: 37%;
  height: 83%;
  overflow-y: auto;
  border-radius: 10px;
  padding: 10px;
}

.chat-button {
  min-width: 90px;
  margin: 0px 18px;
  width: 50%; 
  font-size: 1em; 
}




.sender {
  text-align: left; 
  background-color: #e5e5e5;
  color: black; 
  clear: both;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 6px;
  display: block;
  max-width: 80%;
  border-radius: 10px;
  float: left;
}

.recipient {
  text-align: right; 
  background-color: #0084ff; 
  color: white; 
  clear: both;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 8px;
  display: block;
  max-width: 80%;
  border-radius: 10px;
  width: fit-content;
  float: right; 
}


.centered-button {
  position: absolute;
  bottom: 4%;
  opacity: 0;
  height: 5%;
  width: 6%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.button-container {
  position: absolute;
  bottom: 2%;
  width: 0%;
  text-align: center;
  z-index: 3;
}
@media (min-width: 450px) and (max-width: 600px){
  .phone img {
    width: 85%;
    top: 50%;
  }
  .phone {
    top: 20%;
    
  }
  
  .centered-container {
    left: 53.4%;
    width: 43%;
 
  }

  .chats {
    font-size: 0.8em;
    width: 82%;
  }

  .chat-button {
    min-width: 60px;
    margin: 0px 15px;
    font-size: 0.7em; 
  }


  .ruka-image {
    position: absolute;
    height: 60%;
    top: 89%; 
    left: 50%; 
    z-index: 0; 
    transform: translate(-50%, -50%);
  }
  .welcome-message {
    top: 50%;
 
  }
 
  
  .welcome-message p {
    margin: 20px 0;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    display: block; 
  }
  .welcome-line1 {
    font-size: 1.8rem;
  }
  .welcome-line2 {
    font-size: 1.3rem;
  }
  .welcome-line3 {
    font-size: 1.7rem;
  }

  .welcome-line4 {
    font-size: 0.6rem;
  }

  .welcome-line5 {
    font-size: 3rem;
  }
 
 

}
@media (min-width: 550px) and (max-width: 649px){
  .phone img {
    width: 85%;
    top: 0%;
 
  }

  .phone {
    top: 3%;
    
  }
  
  .centered-container {
    left: 53.4%;
    width: 43%;
 
  }

  .centered-button{
    top:90%;
  }
  .chats {
    font-size: 0.8em;
    width: 82%;
  
   
  }

 
  .chat-button {
    min-width: 70px;
    margin: 0px 20px;
    font-size: 0.8em; 
  }
  .centered-container {
    top: 50%;
  }


  .welcome-message {
    top: 50%;
 
  }
 
  
  .welcome-message p {
    margin: 20px 0;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    display: block; 
  }
  .welcome-line1 {
    font-size: 2rem;
  }
  .welcome-line2 {
    font-size: 1.3rem;
  }
  .welcome-line3 {
    font-size: 2.2rem;
  }

  .welcome-line4 {
    font-size: 0.8rem;
  }

  .welcome-line5 {
    font-size: 3rem;
  }
 

  .ruka-image {
    position: absolute;
    height: 75%;
    top: 90%; 
    left: 53%; 
    z-index: 0;
    transform: translate(-50%, -50%);
  }
 
}

@media (min-width: 650px) and (max-width: 749px){
.phone img {
    width: 85%;
    top: 0%;
 
  }

  .phone {
    top: 3%;
    
  }
  
  .centered-container {
    left: 53.4%;
    width: 43%;
    top: 50%;
 
  }
  .centered-button{
    transform: translateX(-50%);
    top: 100%;
  }

  .chats {
    font-size: 0.8em;
    width: 82%;
  
   
  }

 
  .chat-button {
    min-width: 90px;
    margin: 0px 20px;
    font-size: 1em; 
  }



  .welcome-message {
    top: 50%;
 
  }
 
  
  .welcome-message p {
    margin: 20px 0;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    display: block; 
  }
  .welcome-line1 {
    font-size: 2rem;
  }
  .welcome-line2 {
    font-size: 1.3rem;
  }
  .welcome-line3 {
    font-size: 2.2rem;
  }

  .welcome-line4 {
    font-size: 0.8rem;
  }

  .welcome-line5 {
    font-size: 3rem;
  }
 

  .ruka-image {
    position: absolute;
    height: 75%;
    top: 90%; 
    left: 53%; 
    z-index: 0;
    transform: translate(-50%, -50%);
  }

}


@media (min-width: 750px) and (max-width:849px){
  .phone img {
    width: 45%;

  }
  .phone {
    top:15%;
    left: -13%;
    width: 130%;
    
  }

  .centered-container {
 
    left: 61.9%;
    width: 43%;
  
  }
  .centered-button{
    transform: translateX(-50%);
    top: 95%
  }

  .chats {
    font-size: 0.7em;
    width: 41%;
    height: 83%;
   
  }

  .chat-button {
    min-width: 70px;
    margin: 0px 15px;
    width: 50%; 
    font-size: 0.6em; 
  }

  .welcome-line1 {
    font-size: 1.8rem;
  }
  .welcome-line2 {
    font-size: 1.3rem;
  }
  .welcome-line3 {
    font-size: 1.9rem;
  }

  .welcome-line4 {
    font-size: 0.6rem;
  }
  .welcome-line5 {
    font-size: 2.5rem;
  }
  
  
  .ruka-image {
    position: absolute;
    height: 60%;
    top: 68%;
    left: 31%; 
    z-index: 0; 
  }
}


@media (min-width: 850px) and (max-width:949px){
  .phone img {
    width: 45%;

  }
  .phone {
    top:10%;
    
  }

  .centered-container {
 
    left: 61.9%;
    width: 43%;
  
  }
  .centered-button{
    transform: translateX(-50%);
    top: 85%
  }

  .chats {
    font-size: 0.7em;
    width: 41%;
    height: 83%;
   
  }

  .chat-button {
    min-width: 70px;
    margin: 0px 10px;
    width: 50%; 
    font-size: 0.6em; 
  }

  .welcome-line1 {
    font-size: 1.8rem;
  }
  .welcome-line2 {
    font-size: 1.3rem;
  }
  .welcome-line3 {
    font-size: 1.9rem;
  }

  .welcome-line4 {
    font-size: 0.6rem;
  }
  .welcome-line5 {
    font-size: 2.5rem;
  }
  
  
  .ruka-image {
    position: absolute;
    height: 70%;
    top: 51%;
    left: 31%; 
    z-index: 0; 
  }
}


@media (min-width: 950px) and (max-width:1050px){
  .phone img {
    width: 45%;

  }
  .phone {
    top:10%;
    
  }

  .centered-container {
 
    left: 61.9%;
    width: 43%;
  
  }
  .centered-button{
    transform: translateX(-50%);
    top: 85%
  }

  .chats {
    font-size: 0.9em;
    width: 41%;
    height: 83%;
   
  }

  .chat-button {
    min-width: 70px;
    margin: 0px 14px;
    width: 50%; 
    font-size: 0.8em; 
  }

  .welcome-line1 {
    font-size: 1.8rem;
  }
  .welcome-line2 {
    font-size: 1.3rem;
  }
  .welcome-line3 {
    font-size: 1.9rem;
  }

  .welcome-line4 {
    font-size: 0.6rem;
  }
  .welcome-line5 {
    font-size: 2.5rem;
  }
  
  
  .ruka-image {
    position: absolute;
    height: 70%;
    top: 51%;
    left: 31%; 
    z-index: 0; 
  }
}


@media (min-width: 1050px) and (max-width:1149px){
  .phone img {
   
    width: 45%;
  }
  .phone {
    top:8%
  }

  .centered-container {
 
    left: 61.9%;
    width: 43%;
  
  }
  .centered-button{
    transform: translateX(-50%);
    top: 86%
  }

  .chats {
    font-size: 0.9em;
    width: 41%;
    height: 83%;
   
  }

  .chat-button {
    min-width: 70px;
    margin: 0px 20px;
    width: 50%; 
    font-size: 0.8em; 
  }

  .welcome-line1 {
    font-size: 1.8rem;
  }
  .welcome-line2 {
    font-size: 1.3rem;
  }
  .welcome-line3 {
    font-size: 2rem;
  }

  .welcome-line4 {
    font-size: 0.7rem;
  }
  
  
  .ruka-image {
    position: absolute;
    height: 70%;
    top: 51%;
    left: 32%; 
    z-index: 0; 
  }
}


@media (min-width: 1150px) and (max-width:1249px){
  .phone img {
   
    width: 45%;
  }

  .centered-container {
 
    left: 61.9%;
    width: 43%;
  
  }
  .centered-button{
    transform: translateX(-50%);
    top: 85%
  }

  .chats {
    font-size: 0.9em;
    width: 41%;
    height: 83%;
   
  }

  .chat-button {
    min-width: 80px;
    margin: 0px 18px;
    width: 50%; 
    font-size: 1em; 
  }

  .welcome-line1 {
    font-size: 1.8rem;
  }
  .welcome-line2 {
    font-size: 1.3rem;
  }
  .welcome-line3 {
    font-size: 2.1rem;
  }

  .welcome-line4 {
    font-size: 0.8rem;
  }
  
  
  .ruka-image {
    position: absolute;
    height: 76%;
    top: 45%;
    left: 33%; 
    z-index: 0; 
  }

}


@media (min-width: 1250px) and (max-width:1349px){
  .phone img {
   
    width: 45%;
  }

  .centered-container {
 
    left: 61.9%;
    width: 43%;
  
  }

  .chats {
    font-size: 0.9em;
    width: 41%;
    height: 83%;
   
  }

  .chat-button {
    min-width: 90px;
    margin: 0px 18px;
    width: 50%; 
    font-size: 1em; 
  }
  
  
  .ruka-image {
    position: absolute;
    height: 76%;
    top: 45%;
    left: 35%; 
    z-index: 0; 
  }

  .welcome-line3 {
    font-size: 2.2rem;
  }

  .welcome-line4 {
    font-size: 0.8rem;
  }
  

 
  

}





