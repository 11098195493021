@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

body, html {
    overflow-x: hidden;
}
.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    
  }
  
  .about-image {
    width: 15vw;
    height: auto;

  }
  
  .about-text {
   
    max-width: 600px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    max-width: 40vw; 
    margin-top: 2vw; 
    margin-left: 10vw; 
    overflow-y: auto;
    max-height: calc(100vh - 150px);


  }

@media (min-width: 260px) and (max-width: 550px) {
    .about-text {
        font-size: 100px

    }

}

@media (min-width: 450px) and (max-width: 750px) {
    .about-text {
        font-size: 0.72em;

    }
}


@media (min-width: 751px) and (max-width: 1000px) {
  .about-text {
    font-size: 0.79em;

  }
}

@media (min-width: 1000px) and (max-width: 1250px) {
  .about-text {
    font-size: 0.9em;

}
  
}
 