@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
body{
    overflow-x: hidden;
    margin: 0;
    padding: 0;


}

.navbar {
    background-color: white;
    width:100%;
}

.navbar-brand {
    font-size: 32px; 
    font-family: 'Arial', sans-serif;
    color: #fff; 
}

    .navbar-brand img {
        max-height: 130px; 
        margin-right: 40px;
        margin-left: 40px;
    }

.navbar-nav .nav-link {
    color: #fff; 
    font-weight: bold;
    font-size: 17px;
    margin-right: 12px;
}

.navbar-nav .nav-item .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1); 
    border-radius: 5px; 
    transform: scale(1.1);
}


.navbar-toggler-icon {
    background-color: #fff; 
}

.partial-height-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center; 
}

.textik {
    font-size: 3em;
    text-shadow: black 4px 0px 4px;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.textik2 {
    font-size: 2em;
    text-shadow: black 4px 0px 4px;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.partial-height-background button.join-button {
    background-color: #007bff;
    color: white;
    border: none;
    width: 10%; 
    padding: 4px 10px; 
    font-family: 'Roboto', sans-serif;
    margin-top: 10px;
    border-radius: 10px;
    letter-spacing: 1px;
}


.con {
    text-align:center;

}

.con1 {
    display: grid;
    grid-gap: 10px;
    text-align: center;
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
    grid-template-columns: 1fr 1fr;
}
.about-us-container {
   
    background-color: orange; 
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2); 
    padding: 20px; 
    border-radius: 10px;
    display:flex;
    flex-direction:column;
}

.about-us-title {
    font-size: 3em;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-align:left;
}


.about-us-text {
    font-size: 1.5em;
    color: white;
    text-align: left;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight:100;
    
}




.empty-block {
   
    background-color: magenta; 
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2); 
    padding: 20px; 
    border-radius: 10px; 
    display:flex;
    flex-direction:column;
}
.empty-block2 {
    height: 65vh;
    width: 96.6vw;
    background-color: rebeccapurple;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 10px;
    margin: 10px 25px 0;
    display: flex;
    flex-direction: column;
}

.empty-block2-title {
    font-size: 2em;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-align: center;
}


#contact-form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
}

    #contact-form label {
        color: white;
        margin-bottom: 8px;
    }

    #contact-form input,
    #contact-form textarea {
        padding: 8px;
        margin-bottom: 16px;
        border: 1px solid white;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
    }

    #contact-form button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
    }


.read-more-button {
    background-color: #007bff;
    color: white;
    border: none;
    width: 20%;
    font-family: 'Roboto', sans-serif;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: auto; 
    margin-left: auto; 
    display: block; 
}





@media (min-width: 260px) and (max-width: 550px) {

    .navbar {
        width: 100%;
    }

    .navbar-brand img {
        max-height: 80px; 
        margin-left: 20px;
    }

    .navbar-nav {
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
    }


        .navbar-nav .nav-item {
            margin-right: 20px; 
        }

        .navbar-nav .nav-link {
            font-size: 15px;
            margin-left: auto;
        }

    .navbar-toggler {
        margin-left: auto;
    }

    .custom-search {
        margin-right: auto;
        max-width: 120px;
        max-height: 60px;
    }

    .textik {
        font-size: 2em;
    }

    .textik2 {
        font-size: 1.5em; 
    }

    .about-us-title {
        font-size: 2em;
    }

    .partial-height-background button.join-button {
        width: 17%;
    }

    .about-us-text {
        font-size: 1em;
    }

    .con1 {
        grid-template-columns: 1fr;
    }

    .read-more-button {
        width: 30%;
    }

    .empty-block2 {
        height: 65vh;
        width: 90vw;
    }
}

@media (min-width: 450px) and (max-width: 850px) {
    .navbar {
        width: 100%;
    }

    .navbar-brand img {
        max-height: 100px;
        margin-right: 1px; 
        margin-left: 20px;
    }
    .navbar-nav {
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
    }



        .navbar-nav .nav-link {
            font-size: 15px;
            margin-left: auto;
        }

    .navbar-toggler {
        margin-left: auto;
    }

    .navbar-nav .nav-item {
        margin-top: 60px;
        margin-right: 5px; 
    }


   
    .custom-search {
        margin-right: auto;
        max-width: 120px;
        max-height: 60px;
    }


    .textik {
        font-size: 2.5em; 
    }

    .textik2 {
        font-size: 1.7em; 
    }

    .about-us-title {
        font-size: 2.2em;
    }


    .about-us-text {
        font-size: 1.2em;
    }
    .partial-height-background button.join-button {
        width: 17%;
    }


    .con1 {
        grid-template-columns: 1fr;
    }

    .read-more-button {
        width: 30%;
        font-size:1em;
    }

    .empty-block2 {
        height: 65vh;
        width: 94.1vw;
    }

}


@media (min-width: 750px) and (max-width: 1250px) {
    .navbar {
        width: 100%;
    }

    .navbar-brand img {
        max-height: 140px; 
        margin-right: 40px; 
        margin-left: 20px;
    }

    .navbar-nav {
        flex-direction: row !important;
        align-items: center;
        justify-content: center;
    }



    .textik {
        font-size: 3em; 
    }

    .textik2 {
        font-size: 2em; 
    }

    .partial-height-background button.join-button {
        width:11%;
    }


    .about-us-text {
        font-size: 1.3em;
    }

    .con1 {
        grid-template-columns: 1fr 1fr;
    }

    .read-more-button {
        width: 30%;
        font-size: 1em;
    }

    .empty-block2 {
        height: 65vh;
        width: 96vw;
    }

}


@media (min-width: 1000px) and (max-width: 1400px) {


}